'use client';

import { Column, Cover, Heading, Row, Spacer } from 'oddsgate-ds';
import { IPost, Taxonomy } from '@/lib/wordpress/_types/archiveGrid';
import { Suspense, useEffect, useState } from 'react';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayBackground from '@/components/common/DisplayBackground/DisplayBackground';
import GravityForm from '@/components/common/GravityForm/GravityForm';
import NavigationEvents from '@/components/common/NavigationEvents/NavigationEvents';
import { WpClientRequest } from '@/lib/wordpress/wpClientRequest';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';
import { removeScroll } from '@/lib/helpers/scrollHelpers';
import { styled } from 'styled-components';
import { useGlobalContext } from '@/lib/globalContext';

const StyledFormWrapper = styled.div`
    background: linear-gradient(
        to bottom,
        var(--color-primary50) 0%,
        var(--color-primary50) 50%,
        var(--color-third50) 50%,
        var(--color-third50) 100%
    );
`;

const CareersPost = (post: IPost) => {
    const state = useGlobalContext();
    const [formData, setFormData] = useState<any>();
    const [shareOpen, setShareOpen] = useState(false);
    const lang = state.lang;

    const formID = post?.acf?.form_id || state?.dict?.custom?.hiringForm;

    useEffect(() => {
        WpClientRequest({
            post_type: `gf/v2/forms/${formID}/`,
            isContent: false,
            isForms: true,
            variables: {
                lang,
            },
        }).then((res) => {
            setFormData({ formId: formID, formData: res });
        });
    }, [lang]);

    useEffect(() => {
        removeScroll(shareOpen, state);
    }, [shareOpen, state]);

    return (
        <div>
            <Suspense>
                <NavigationEvents
                    translatedURI={post?.translatedURI}
                    acf={{
                        page_style: 'dark',
                        pageTitle: `${state?.dict?.post_types?.careers}: ${post.title}`,
                    }}
                />
            </Suspense>
            <div className="position-relative">
                <DisplayBackground
                    alt={''}
                    url={'/assets/components/careersBg.png'}
                    backgroundSize={'auto 80%'}
                    backgroundPosition={`center top`}
                    backgroundRepeat="no-repeat"
                />
                <Cover
                    imageElement={
                        <DisplayBackground
                            alt={post?.title as string}
                            url={post?.image as string}
                            backgroundSize={'auto 90%'}
                            backgroundPosition={`bottom right`}
                            backgroundRepeat="no-repeat"
                            className={'d-none d-md-block'}
                        />
                    }>
                    <Spacer height={8} />
                    <Row>
                        <Column md={12}>
                            <Heading
                                tag="h1"
                                size="display"
                                className="color-primary50 text-uppercase">
                                {post.title}
                            </Heading>
                        </Column>
                        <Column md={6}>
                            <Spacer height={6} />
                            <Heading tag="span" size="h4" className="fw-bold">
                                {post?.acf?.small_description}
                            </Heading>
                        </Column>
                    </Row>
                    <Spacer height={8} />
                </Cover>

                <Spacer height={10} />

                <div>
                    {!!(post?.blocks as [])?.length &&
                        (post?.blocks as []).map(
                            (block: BlocksProps, index: number) => {
                                return displayBlock(block, index);
                            }
                        )}
                </div>
            </div>

            <StyledFormWrapper>
                <Row>
                    <Column md={1}></Column>
                    <Column md={10}>
                        <Spacer height={11} />
                        <div className="bg-third10 pt-8 pb-8 p-6 p-md-9 pt-md-9 pb-md-9 radius-md">
                            <Heading
                                tag="h3"
                                size="h2"
                                className="color-primary50 text-uppercase">
                                {state?.dict?.custom?.careersForm}
                            </Heading>
                            <Spacer height={8} />
                            {formData && <GravityForm {...formData} />}
                        </div>
                        <Spacer height={10} />
                    </Column>
                    <Column md={1}></Column>
                </Row>
            </StyledFormWrapper>
        </div>
    );
};
export default CareersPost;
